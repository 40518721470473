import React from 'react';
import { Link } from 'react-router-dom';

import { isEven } from '../../../utils';

import './Banner.css';

interface BannerProps {
    title?: string;
    image?: string;
    children?: any;
}

export const Banner = (props: BannerProps) => {
    const { title, image, children } = props;
    

    return (
        <div className="banner" style={{ backgroundImage: image ? `url(${image})` : 'none' }}>
            {title && <div className="title-container"><h1>{title}</h1></div>}
            {children}
        </div>
    )
}