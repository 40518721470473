import React from 'react';
import { Image } from 'antd';


import itTakesTwotoTalkPdf from '../../static/docs/ITTT-parent-brochure-editable.pdf';
import moreThanWordsPdf from '../../static/docs/MTW-parent-brochure-editable.pdf';
import seNecesitanDosPdf from '../../static/docs/Se-Necesitan-Dos-para-Hablar.pdf';
import masQuePalabrasPdf from '../../static/docs/Mas-que-Palabras.pdf';

import music01 from '../../static/img/music01.jpeg';
import boyCar from '../../static/img/boy_car.jpeg';
import camp02 from '../../static/img/camp02.jpeg';
import parachute from '../../static/img/parachute.jpeg';
import teamphoto from '../../static/img/teamphoto.jpeg';

import './TherapyServices.css';

interface TherapyServicesProps {

}

export const TherapyServices = (props: TherapyServicesProps) => {
    return (
        <div className="therapy-services page">
            <div className="content-block">
                <div className="content-block-text">
                    <h2>Types of Services</h2>
                    <p>The types of services that a child may qualify for is directly related to their developmental needs and the results of the initial evaluation. All Early Start therapy and specialist services are provided in a natural, fun and playful manner that is developmentally appropriate for each child and their specific needs. These may include all or some of the services outlined below:</p>
                </div>
                <Image src={music01}/>
            </div>
            <div className="content-block">
                <div className="content-block-text">
                    <h2>Speech &amp; Language Therapy (ST)</h2>
                    <p>This type of therapy service is conducted by a licensed and Mastered Degreed professional in the state of California who provides a child with one or both aspects of communication such as sound pronunciation (articulation) or language (comprehension and expressive communication). A child may also be provided services by a licensed Speech &amp; Language Assistant (SLPA) under the supervision and guidance of a fully licensed speech and language pathologist.</p>
                    <ul>
                        <li><a href={itTakesTwotoTalkPdf} target="blank" rel="noreferrer">It Takes Two to Talk Parent Brochure</a></li>
                        <li><a href={moreThanWordsPdf} target="blank" rel="noreferrer">More Than Words Parent Brochure</a></li>
                        <li><a href={seNecesitanDosPdf} target="blank" rel="noreferrer">Se Necesitan Dos para Hablar</a></li>
                        <li><a href={masQuePalabrasPdf} target="blank" rel="noreferrer">Más que Palabras</a></li>
                    </ul>
                </div>
                <Image src={boyCar}/>
            </div>
            <div className="content-block">
                <div className="content-block-text">
                    <h2>Occupational Therapy (OT)</h2>
                    <p>Children who receive occupational therapy do so in order to help with sensory awareness, motor skill development, daily living skills, learning and/or healthy behavior as needed and indicated. This type of therapy is conducted by a state licensed occupational therapist who holds at least a Bachelor of Science degree in OT or by an OT Assistant (OTA) who has successfully completed a certification program.</p>
                </div>
                <Image src={camp02}/>
            </div>
            <div className="content-block">
                <div className="content-block-text">
                    <h2>Physical Therapy (PT)</h2>
                    <p>Physical Therapy services assist children with their overall motor development, strength, coordination and endurance skills and are provided by a licensed and degreed individual. These services may also be provided by a Physical Therapy Assistant (PTA) who has graduated from an accredited program and holds both a state license as well as a certificate in the field.</p>
                </div>
                <Image src={teamphoto}/>
            </div>
            <div className="content-block">
                <div className="content-block-text">
                    <h2>Early Intervention Specialist/Child Development Services (EI/CDS)</h2>
                    <p>Early Intervention (EI) services help children with overall learning and development related to daily skills, pre-academic learning (ex. Colors), cognition, motor skills and communication. This type of service is provide by An Early Intervention Specialist (EIS) or an assistant (EIA) that holds certification and a Bachelor&apos;s degree in an educational-related field.</p>
                </div>  
                <Image src={parachute}/>
            </div>
            <div className="content-block text-only">
                <div className="content-block-text">
                    <h2>Vision Specialist Services</h2>
                    <p>These services are for our children whom present with unique visual challenges and require specialized education services to those clients who present with a variety of visual impairments. This discipline draws upon a number of specific resources and methods to accomplish all of the traditional goals of education and is provided by a teacher of the visually impaired.</p>
                </div>
            </div>
            <div className="content-block text-only">
                <div className="content-block-text">
                    <h2>Counseling Services</h2>
                    <p>MP Health Counseling offers a variety of mental health services to individuals, couples and children. We also offer group therapy services for single moms/teens and parent groups for children diagnosed with Autism Spectrum Disorder. We also address numerous issues such as anxiety, depression, grief and loss, PTSD, attachment disorders and substance abuse. Please call our office to make an appointment with one of our two counselors who speak both English and Spanish.</p>
                </div>
            </div>
            <div className="content-block text-only">
                <div className="content-block-text">
                    <h2>Music &amp; Language</h2>
                    <p>This weekly class and program has been in operation for over 15 years and is conducted by our 2-person team of a music teacher and a speech and language pathologist. Here, the children are able to experience great fun while improving their speech and language communication through movement and music. Parents and caregivers attend this hour-long class each week to help their children speak, learn specific communication strategies and have the the opportunity to speak with our speech and language pathologist about their child&apos;s specific needs.</p>
                </div>
            </div>
        </div>
    );
}