import React, { useEffect } from 'react';
import { Card, Col, Row } from 'antd';

import './SawyerWidget.css';

interface SawyerWidgetProps {
    header?: string;
    id: string;
    src: string;
}

const SAWYER_DATATAG = 'data-sawyertools';
const SAWYER_DATATAG_VALUE = 'sawyertools';

export const SawyerWidget = (props: SawyerWidgetProps) => {
    const { header, id, src } = props;

    const sawyerDivId = `sawyer-${id}`;

    useEffect(() => {
        const sawyer = document.getElementById(sawyerDivId);
        const script = document.createElement('script');
        
        script.type = 'application/javascript';
        script.src = src;
        script.async = true;
        script.setAttribute(SAWYER_DATATAG, SAWYER_DATATAG_VALUE);
        script.id = id;

        const container = document.createElement('div');
        if (header) {
            const h4 = document.createElement('h4');
            h4.innerText = header;
            container.appendChild(h4);
        }

        container.appendChild(script);
        if (sawyer) sawyer.appendChild(script)
    
        return () => {
                document.body.removeChild(script);
        }
      }, [props.id, props.src]);

    return (
        <div className="sawyer-widget-wrapper">
            {header ? <div className="sawyer-widget-header">
                <h2>{header}</h2>
            </div> : null}
            <div className="sawyer-widget-container">
                <div id={sawyerDivId}></div>
            </div>
        </div>
    );
}