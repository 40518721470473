import React from 'react';
import { Image } from 'antd';

import { PageHeader, SiteButton, SawyerWidget } from '../../common';
import { EMAIL, EMPLOYMENT_APPLICATION_LINK } from '../../../utils';

import './widgets.css';

interface MariposasAtascaderoProps {

}

export const MariposasAtascadero = (props: MariposasAtascaderoProps) => {
    const HEADER = 'Mariposas Atascadero';
    const ID = 'SA_AWEJwTRED86mETO1-UcRwJxg3SiLPP3i';
    const SRC = 'https://hisawyer.com/embed/AWEJwTRED86mETO1-UcRwJxg3SiLPP3i.js';

    return (
        <div className="atascadero page">
            <PageHeader header={'Mariposas Atascadero'} />
            <div className="atascadero-blocks">
               <SawyerWidget 
                    id={ID}
                    src={SRC}
                />
            </div>
        </div>
    );
}