import React from 'react';
import { Routes, Route } from "react-router-dom";

import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';

import { Home } from './components/Home/Home';
import { ContactUs, Employment, Locations } from './components/ContactUs';
import { EurekaMariposasPlayCenter, MariposasAtascadero, MariposasSantaBarbara } from './components/ContactUs/widgets';
import { TherapyServices } from './components/TherapyServices/TherapyServices';
import { ParentsAndFamilies } from './components/ParentsAndFamilies/ParentsAndFamilies';
import { EarlyStartServices } from './components/EarlyStartServices/EarlyStartServices';
import { Argentina } from './components/Argentina/Argentina';

import './App.css';
import 'antd/dist/antd.min.css';

function App() {
  return (
    <div className="App">
      <Header />
      <div id="content">
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="therapy_services" element={<TherapyServices />} />
            <Route path="early_start_services" element={<EarlyStartServices />} />
            <Route path="parents_and_families" element={<ParentsAndFamilies />} />
            <Route path="argentina" element={<Argentina />} />
            <Route path="contact_us" element={<ContactUs />} />
            <Route path="contact_us/locations" element={<Locations />} />
            <Route path="sign_up/eureka" element={<EurekaMariposasPlayCenter />} />
            <Route path="sign_up/atascadero" element={<MariposasAtascadero />} />
            <Route path="sign_up/santabarbara" element={<MariposasSantaBarbara />} />
            <Route path="contact_us/employment" element={<Employment />} />
          </Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
