import React, { useState } from 'react';

import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import './Expandable.css';

interface ExpandableProps {
    header?: string;
    children?: any;
}

export const Expandable = (props: ExpandableProps) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <div className="expandable">
            <div className="expandable-header-container">
                <span className="expandable-header" onClick={() => setExpanded(!expanded)}>
                    { expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined /> }
                    { ' ' }
                    { props.header }
                </span>
            </div>
            <div className={`expandable-text-container${expanded ? ' expanded' : ''}`}>
                {props.children}
            </div>
        </div>
    )
}