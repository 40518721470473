import React from 'react';
import { Image } from 'antd';

import { PageHeader, SiteButton, SawyerWidget } from '../../common';
import { EMAIL, EMPLOYMENT_APPLICATION_LINK, PHONE } from '../../../utils';

import './widgets.css';

interface MariposasSantaBarbaraProps {

}

export const MariposasSantaBarbara = (props: MariposasSantaBarbaraProps) => {
    const HEADER = 'Mariposas Santa Barbara';
    const ID = 'SA_qQBF5QdQw5FBwLj4llm-t4z30VyJyE8b';
    const SRC = 'https://hisawyer.com/embed/qQBF5QdQw5FBwLj4llm-t4z30VyJyE8b.js';

    const subheader = () => (
        <>
            <span style={{ fontSize: '1.5rem' }}>215 W Mission St, Santa Barbara, CA 93101</span>
            <br />
            <span style={{ fontSize: '1.5rem' }}>{PHONE}</span>
        </>
    )

    return (
        <div className="santabarbara page">
            <PageHeader header={HEADER} subheader={subheader()}/>
            <div className="santabarbara-blocks">
               <SawyerWidget 
                    id={ID}
                    src={SRC}
                />
            </div>
        </div>
    );
}