import { BASE_URL, BASE_PATH } from './';

export const API_BASE_URL = `${BASE_URL}${BASE_PATH}/api`;
export const API_EMAIL_URL = `${API_BASE_URL}/email/`;

export const sendEmail = async (name: string, email: string, subject?: string, message?: string, recaptcha?: string) => {
    try {
        const body = {
            name,
            email,
            subject,
            message,
            'g-recaptcha-response': recaptcha
        }
        const response = await fetch(API_EMAIL_URL, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            method: "POST",
            body: JSON.stringify(body)
        });
        return response.json();
    } catch (e) {
        console.error(e);
        return null;
    }
}