import React, { useState } from 'react';
import { Card, Col, Row, Image } from 'antd';

import eurekaCenter1 from '../../../static/img/whats-new/image1.jpg';
import eurekaCenter2 from '../../../static/img/whats-new/image2.jpg';
import eurekaCenter3 from '../../../static/img/whats-new/image3.jpg';
import eurekaCenter4 from '../../../static/img/whats-new/image4.jpg';
import eurekaCenter6 from '../../../static/img/whats-new/image6.jpg';
import eurekaCenter7 from '../../../static/img/whats-new/image7.jpg';

import circleOfSecurity from '../../../static/img/whats-new/image5.jpg';

import './LatestEvents.css';

interface LatestEventsProps {
}

const LATEST_TEXT = `WHATS NEW`;

export const LatestEvents = (props: LatestEventsProps) => {
    const [eurekaVisible, setEurekaVisible] = useState(false);

    return (
        <div className="latest-events-wrapper">
            <h2 className="latest-header">{LATEST_TEXT}</h2>
            <div className="latest-block">
                <div className="latest-block-1"><h3>Eureka Center</h3></div>
                <div className="latest-block-2">
                    <Image 
                        preview={{ visible: false }}
                        width={'45vh'} 
                        src={eurekaCenter1}
                        onClick={() => setEurekaVisible(true)}
                        />
                    <div className="latest-block-preview">
                        <Image.PreviewGroup preview={{ visible: eurekaVisible, onVisibleChange: vis => setEurekaVisible(vis) }}>
                            <Image width={200} src={eurekaCenter1} />
                            <Image width={200} src={eurekaCenter2} />
                            <Image width={200} src={eurekaCenter3} />
                            <Image width={200} src={eurekaCenter4} />
                            <Image width={200} src={eurekaCenter6} />
                            <Image width={200} src={eurekaCenter7} />
                        </Image.PreviewGroup>
                    </div>
                </div>
            </div>
            <div className="latest-block">
                <div className="latest-block-1">
                    <p>
                        <span className="bold">&quot;The Circle of Security&quot;</span>, a relationship-based parenting program with a strong foundation on the attachment theories for families and children in need of building secure relationships for a successful parenting experience</p>
                </div>
                <div className="latest-block-2">
                    <Image preview={{ visible: false }} width={'40vh'} src={circleOfSecurity} />
                </div>
            </div>
            <div className="latest-block">
                <div className="latest-block-0">
                    <h3>Hanen Program</h3>
                    <p>
                        <span className="bold">&quot;It Takes Two to Talk (ITTT)&quot;</span>, a relationship-based parenting program with a strong foundation on the attachment theories for families and children in nis designed specifically for parents of children ages 5 and under, who have been identified as having a language delay. A Hanen-certified speech-language pathologist leads 7 small-group parent training sessions. The program will also include 3 individual visits provided by the speech-language pathologist. The parent will be guided using Hanen strategies to discover best modifications for their child. 
                    </p>
                    <p>
                        <span className="bold">&quot;More Than Words (MTW)&quot;</span> is designed specifically for parents of children ages 5 and under on the autism spectrum with social communication difficulties.A Hanen-certified speech-language pathologist leads 7 small-group parent training sessions. The program will also include 3 individual visits provided by the speech-language pathologist. The parent will be guided using Hanen strategies to discover best modifications for their child. 
                    </p>
                </div>
            </div>
            <div className="latest-block">
                <div className="latest-block-0">
                    <p>
                        <span className="bold">
                            Proudly supporting Casa Ludovica!  We are accepting donations of any amount (even the smallest will help).  <span className="italics underline">Contact our office for donations.</span>
                        </span>
                    </p>
                    <p>
                        <a className="bold" href="https://fundacionludovica.org.ar/" target="_blank" rel="noreferrer">https://fundacionludovica.org.ar/</a>
                    </p>
                </div>
            </div>
        </div>
    );
};
