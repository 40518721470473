import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Input, Button, Popover } from 'antd';
import MailIcon from '@mui/icons-material/Mail';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import WorkIcon from '@mui/icons-material/Work';

import { PageHeader, SiteButton, Success } from '../common';
import { RECAPTCHA_SITE_KEY, EMAIL, HUMBOLDT_EMAIL, ATASCADERO_PHONE, PHONE, ADDRESS, ATASCADERO_ADDRESS, EMPLOYMENT_APPLICATION_LINK, FAX } from '../../utils';
import { isEmail } from '../../utils/validate';
import { sendEmail } from '../../utils/api';

import './ContactUs.css';

const { TextArea } = Input;

interface ContactUsProps {

}

export const ContactUs = (props: ContactUsProps) => {

    return (
        <div className="contact-us page">
            <PageHeader header={'Contact Us'} subheader={'Get in Touch'}/>
            <div className="contact-us-blocks">
                <div className="contact-us-block">
                    <div className="contact-us-block-icon-wrapper">
                        <MailIcon className="contact-us-block-icon" htmlColor='#e4a40f' fontSize={'large'}/>
                    </div>
                    <h3>Write or Call Us</h3>
                    <div>
                        <h4>Santa Barbara Office:</h4>
                        <p>{EMAIL}</p>
                        <p>Phone: {PHONE}</p>
                        <p>Fax: {FAX}</p>
                    </div>
                    <div>
                        <h4>Atascadero Office:</h4>
                        <p>{ATASCADERO_PHONE}</p>
                    </div>
                    <div>
                        <h4>Eureka Office: </h4>
                        <p>redwoodcoastreferrals@mariposasproject.com</p>
                        <p>707-250-8225</p>
                        <p>1590 Myrtle Avenue, Eureka, CA 95501</p>
                    </div>
                    <div>
                        <h4>Del Norte Development Center</h4>
                        <p>1041 5th Street, Crescent City, CA</p>
                    </div>
                </div>
                <div className="contact-us-block">
                    <div className="contact-us-block-icon-wrapper">
                        <RoomRoundedIcon className="contact-us-block-icon" htmlColor='#e4a40f' fontSize={'large'}/>
                    </div>
                    <h3>Visit Us</h3>
                    <p>{`${ADDRESS.street}, ${ADDRESS.city}, ${ADDRESS.state} ${ADDRESS.zipcode}`}</p>
                    <p>{`${ATASCADERO_ADDRESS.street}, ${ATASCADERO_ADDRESS.city}, ${ATASCADERO_ADDRESS.state} ${ATASCADERO_ADDRESS.zipcode}`}</p>
                </div>
                <div className="contact-us-block">
                    <div className="contact-us-block-icon-wrapper">
                        <WorkIcon className="contact-us-block-icon" htmlColor='#e4a40f' fontSize={'large'}/>
                    </div>
                    <h3>Come Work for Us!</h3>
                    <p className="emphasis">Currently hiring SLP, OT, PT and Child Development Specialists for all locations</p>
                    <p>If interested in applying for any of our positions, please send us an application.</p>
                    <SiteButton url={EMPLOYMENT_APPLICATION_LINK} label={'EMPLOYMENT & APPLICATION'} />
                </div>
            </div>
            <div className="contact-us-form-container">
                <h3>Send Us a Message:</h3>
                <ContactForm />
            </div>
        </div>
    );
}

const ContactForm = () => {
    const recaptchaRef = useRef(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [recaptcha, setRecaptcha] = useState<string | null>(null);

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    // useEffect(() => {
    //     setIsSuccess(false);
    // }, [isError]);

    // useEffect(() => {
    //     if (isError) setIsError(false);
    // }, [name, email])

    const handleRecaptcha = async (value: any) => {
        // if value is null recaptcha expired
        if (value === null) setRecaptcha(null);
        else setRecaptcha(value);
    }

    const handleSubmit = async () => {
        setIsSuccess(false);
        if (!(name && isEmailValid && recaptcha)) return ;

        setIsLoading(true);
        const response = await sendEmail(name, email, subject, message, recaptcha);
        setIsLoading(false);
        if (!response || response.code === 400) { 
            return
        }
        
        setIsSuccess(true);
    }

    return (
        <div className="contact-us-form-wrapper">
            <div className="contact-us-form-line required">
                
                <Input 
                    placeholder="name" 
                    onChange={({ target }) => setName(target.value)}
                />
                <Input 
                    placeholder="email" 
                    onBlur={() => setIsEmailValid(isEmail(email))}
                    status={email ? (isEmailValid ? "" : "error") : ""}
                    onChange={({ target }) => setEmail(target.value)}
                />
            </div>
            <div className="contact-us-form-line">
                <Input 
                    showCount
                    maxLength={100}
                    placeholder="subject" 
                    onChange={({ target }) => setSubject(target.value)}
                />
            </div>
            <div className="contact-us-form-line">
                <TextArea 
                    autoSize={{ minRows: 6 }}
                    placeholder="message" 
                    onChange={({ target }) => setMessage(target.value)}
                />
            </div>
            <div className="contact-us-form-line">
                <Button loading={isLoading} onClick={() => handleSubmit()}>
                    Send
                </Button>
                <br />
                <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY || ''} onChange={handleRecaptcha}/>
            </div>
            <Success 
                isVisible={isSuccess}
                title={"Message successfully sent!"}
                subtitle={"We have received your message and will get back to you shortly"}
                handleClose={() => setIsSuccess(false)}
            />
        </div>
    )
}