import React, { useEffect } from 'react';
import { Image } from 'antd';
import { FaQuoteLeft } from 'react-icons/fa';
import { Banner, SiteButton, PageHeader, CustomCarousel, LatestEvents, TilesGrid, SawyerWidget } from '../common';

import logo from '../../static/img/Mariposas-Project-Logo-scaled-transparent-web-2.png';
import bannerImage from '../../static/img/Connie-R-57e8d609-2b18-4eb1-b84a-cbe1e3a26841-e1584567456703.jpeg';

// Carousel
import parkHulaHoop from '../../static/img/MARIPOSAS/park-hula-hoop.png';
import playGym from '../../static/img/MARIPOSAS/play-gym-1.png';
import waterColors from '../../static/img/MARIPOSAS/water-colors.png';
import silviaAndKids from '../../static/img/MARIPOSAS/silvia-and-kids.png';
import cards from '../../static/img/MARIPOSAS/cards.png';
import yellowSandCup from '../../static/img/MARIPOSAS/yellow-sand-cup.png';
import littleGirlRedBow from '../../static/img/MARIPOSAS/little-girl-red-bow.png';
import babyCardboardPaint from '../../static/img/MARIPOSAS/baby-cardboard-paint.png';
import mariposasProjectLogo from '../../static/img/Mariposas-Project-Logo-scaled-transparent-web-2.png';

// Events
import eurekaCenter1 from '../../static/img/whats-new/image1.jpg';
import eurekaCenter2 from '../../static/img/whats-new/image2.jpg';
import eurekaCenter3 from '../../static/img/whats-new/image3.jpg';
import eurekaCenter4 from '../../static/img/whats-new/image4.jpg';

// Grid
import mpHealthLogo from '../../static/img/MPHealthLogoFull.png';
import circleOfSecurity from '../../static/img/circle-of-security.jpeg';
import campMariposasLogo from '../../static/img/camp-mariposas-logo.png';
import prenatalStimulation from '../../static/img/prenatal-stimulation.png';



import './Home.css';

interface HomeProps {

}

const BANNER_TEXT = `"Two Great things you can give your children: one is roots and the other is wings." - Hodding Carter`

const CAROUSEL_TEXT = 'WHAT WE OFFER';
const CAROUSEL_IMAGES = [
    {
        title: `MARIPOSAS PROJECT`,
        image: mariposasProjectLogo,
        url: '#',
        children: <>
            <h3>Mariposas Project</h3>
            <ul>
                <li>Hanen Program</li>
                <li>Circle of Security</li>
            </ul>
            <p>Offered in English and Spanish.</p>
        </>
    },
    {
        title: `MP HEALTH`,
        image: mpHealthLogo,
        url: 'https://mphealthsb.com',
        children: <>
            <h3>MP Health</h3>
            <ul>
                <li>Hanen Program</li>
                <li>Circle of Security - We will provide services to any preschool or group of parents interested in group sessions. Offered in English and Spanish.</li>
                <li>Prenatal Music Stimulation and Bonding</li>
                <li>Counseling Program</li>
                <li>Speech, Occupational and Physical Therapy</li>
            </ul>
        </>
     },
    { 
        title: `Groups`,
        image: campMariposasLogo,
        children: <>
            <h3>Groups</h3>
            <ul>
                <li>Music and Language Group - Starting in February 2024 - please contact us to register</li>
                <li>Sensory Gym</li>
                <li>Parenting Connect Group - Includes sensory play, information, support, and developmental activities. These groups are lead by a licensed Speech Pathlogist and/or SPA</li>
                <li>Babies and Bubbles (new babies - 12 months/non-walkers)</li>
                <li>Talking Toddlers (18 months - 3 years)</li>
                <li>Chatty Children (3 - 4 years)</li>
            </ul>
        </>
    },
    { 
        title: `CAMP MARIPOSAS`,
        image: campMariposasLogo,
        children: <>
            <p>
                A summer camp program geared for young and school-aged children with speech and/or occupational therapy needs. Our summer camp program offers a unique perspective on helping children with specific speech, occupational, movement and sensory needs. Camp Mariposas creates opportunities for young children to engage in fun activities that enhance and facilitate their communication and sensory development. With licensed therapists, Camp Mariposas will ensure that every day of camp will foster the speech, language, social skill building and sensory/movement will be put into practice.
            </p>
        </>
     },
    {
        title: `PRENATAL STIMULATION`, 
        image: prenatalStimulation,
        children: <>
            <p>A new preventive program for <b>pregnant/at risk moms</b> at any stage during their pregnancy that <b>fosters communication, stimulation and prenatal bonding through music.</b></p>
        </>
    },
];

export const Home = (props: HomeProps) => {

    return (
        <div className="home" id="home">
            <Banner image={bannerImage}>
                <div className="panel">
                    <h3>Welcome to</h3>
                    <h1>Mariposas Project</h1>
                    <h4>Our Early Start program provides therapeutic services to eligible infants and toddlers from birth through 3 years of age who are in need of early intervention.</h4>
                    <SiteButton url={'contact_us'} label={'Contact Us'} style={{ backgroundColor: '#7a3ea1' }} route/>
                </div>
                <svg version="1.1" id="banner-divider" className="secondary" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1440 126" preserveAspectRatio="none slice">
                    <path className="st0" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z"></path>
                </svg>
            </Banner>
            {/* 
                What We Offer:
                Basic display top to bottom text
            */}
            {/* 
                Whats New:
                Carousel
            */}
            <CustomCarousel 
                header={CAROUSEL_TEXT}
                bannerImage={logo}
                bannerText={BANNER_TEXT}
                items={CAROUSEL_IMAGES}
            />
            <PageHeader header={'Sign Up Here!'} />
            <SawyerWidget 
                id='SA_h8wUdNg269X25Uy6DlCGk9rgXKdylwj5'
                src='https://hisawyer.com/embed/h8wUdNg269X25Uy6DlCGk9rgXKdylwj5.js'
            />
            <LatestEvents />
            
        </div>
    );
}