import React from 'react';
import { Image } from 'antd';

import childPlaygroundJpg1 from '../../static/img/child_playgroundjpg-1.jpeg';
import childToyJpg from '../../static/img/child_toyjpg.jpeg';

import './EarlyStartServices.css';
import { PageHeader } from '../common';

interface EarlyStartServicesProps {

}

export const EarlyStartServices = (props: EarlyStartServicesProps) => {
    return (
        <div className="page early-start-services">
            <PageHeader header={'Early Start Services'} />
            <div className="content-block">
                <div className="content-block-text">
                    <h2>What is Early Start?</h2>
                    <p>Early Start, also known as, Early Intervention, is a program in the state of California that provides therapy and specialized services to children ages birth - 3 (0-36 months). Such children, who may have an established risk condition with a high probability for a developmental disability, may qualify and be seen for services through our agency. If a child is found to be eligible through the local non-profit regional centers, <a href="https://www.tri-counties.org/early-start-services/" target="_blank" rel="noreferrer">Tri-Counties Regional Center (TCRC)</a>, and <a href="https://www.redwoodcoastrc.org/" target="_blank" rel="noreferrer">Redwood Coast Regional Center (RCRC)</a> for the Early Start program, then the process for a referral begins with both TCRC, RCRC and the Mariposas Project (an accredited therapy agency).</p>
                    <p>At the onset of a referral, a full team of individuals from a Service Coordinator (the social worker who does the initial intake) to therapists and specialists (speech, physical, occupational, vision, early intervention) will then assess a child to see if they are eligible to receive any level of our services. If they are, then the assessment team, the TCRC, or RCRC Service Coordinator and the family will have a meeting to review the evaluation and the need for therapy services.</p>
                </div>
                <Image src={childPlaygroundJpg1}/>
            </div>
            <div className="content-block">
                <div className="content-block-text">
                    <h2>Eligibility Criteria</h2>
                    <p>In order for a child to receive any type of therapy or specialist services from our agency, they must first be referred to the Mariposas Project from TCRC, or RCRC, and a Service Coordinator in the Early Start program for an evaluation. After the assessment is completed by the Mariposas Project using a standardized assessment tool, the testing data is then analyzed and scored to determine if a child meets any of the 3 types of criteria for receiving a specific type of service (Developmental Delay, Established Risk or High Risk for Developmental Disability).</p>
                    <h2>Therapy &amp; Specialist Services</h2>
                    <p>Early Start services provide a range of therapy and specialties that support the overall development of children between birth-3 years of age. This program and philosophy of serving the whole child within their family unit is at the cornerstone of this state-funded program. All children receiving services through the Mariposas Project will be seen on either a weekly or monthly basis as stated through an IFSP - Individual Family Service Plan and in a &quot;natural environment&quot; such as their home, preschool, day care or in the community.</p>
                </div>
                <Image src={childToyJpg}/>
            </div>
            <div className="content-block">
                <div className="content-block-text">
                </div>
            </div>
        </div>
    );
}