
export interface Address {
    street: string;
    city: string;
    state: string;
    zipcode: string;
    location?: {
        lat: number;
        lng: number
    }
}
const googleMapsBaseUrl = `https://www.google.com/maps/dir/?api=1`;

export function generateDirectionsUrl(address: Address): string {
  const addressString = `${address.street}, ${address.city}, ${address.state} ${address.zipcode}`;
  return `${googleMapsBaseUrl}&destination=${encodeURIComponent(
    addressString
  )}`;
}