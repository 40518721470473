import React from 'react';
import { Image } from 'antd';

import { PageHeader, SiteButton } from '../common';
import { EMAIL, EMPLOYMENT_APPLICATION_LINK } from '../../utils';
import connieR from '../../static/img/Connie-R-6e216440-8bea-4117-8d5e-e58fc69f37da.jpeg'

import './Employment.css';

interface EmploymentProps {

}

export const Employment = (props: EmploymentProps) => {

    return (
        <div className="employment page">
            <PageHeader header={'Employment'} subheader={'Come work with us!'}/>
            <div className="employment-blocks">
                <div className="employment-block">
                    <h3>How to Apply</h3>
                    <p>We are often seeking additional therapists to hire as Mariposas continues to grow. If you are interested in working in a supportive environment with a flexible schedule and competitive rates, please click <a href={EMPLOYMENT_APPLICATION_LINK} target="_blank" rel="noreferrer">HERE</a> to complete the online employment application and send us your resume and cover letter via e-mail to <a href={`mailto:${EMAIL}`}>{EMAIL}</a></p>
                    <SiteButton url={EMPLOYMENT_APPLICATION_LINK} label={'ONLINE EMPLOYMENT APPLICATION'} style={{ fontWeight: 'bold' }} />
                </div>
                <div className="employment-block">
                    <Image src={connieR} />
                </div>
            </div>
        </div>
    );
}