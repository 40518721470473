import React from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

import './PageHeader.css';

interface PageHeaderProps {
    header?: string;
    subheader?: any;
}

export const PageHeader = (props: PageHeaderProps) => {
    const { header, subheader } = props;

    return (
        <div className="page-header">
            <h2 className="header">{header}</h2>
            <h3 className="subheader">{subheader}</h3>
            <div className="icon-wrapper">
                <CreateOutlinedIcon className="icon" fontSize='large' htmlColor="#035392"/>
            </div>
        </div>
    );
}