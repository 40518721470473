import React from 'react';
import { Image } from 'antd';

import argentinaInstagram from '../../static/img/mariposasproject-ar.png';
import argentinaFlyer from '../../static/img/argentina-flyer.png';

import './Argentina.css';

interface ArgentinaProps {

}

export const Argentina = (props: ArgentinaProps) => {

    return (
        <div className="argentina page">
            <div className="content-block">
                <p>
                    <span className="bold">
                        Proudly supporting Casa Ludovica!  We are accepting donations of any amount (even the smallest will help).  <span className="italics underline">Contact our office for donations.</span>
                    </span>
                </p>
                <p>
                    <a className="bold" href="https://fundacionludovica.org.ar/" target="_blank" rel="noreferrer">https://fundacionludovica.org.ar/</a>
                </p>
            </div>
            <div className="content-block">
                <h3><a href="https://www.youtube.com/watch?v=mvqb5tJ8oW0" style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">{`Mariposas Project Argentina en Somos lo que Hacemos 8/14/2023`}</a></h3>
                <div className="video-container">
                    <div className="video-wrapper">
                        <iframe width={"100%"} height="563" src="https://www.youtube.com/embed/mvqb5tJ8oW0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <div className="content-block">
                <Image src={argentinaFlyer} />
                <h3><a href={"https://forms.gle/jommQiv8Ty2h7NBE8"} target="_blank" rel="noreferrer">{`Link de Inscripción`}</a></h3>
            </div>
            <div className="content-block">
                <a className="argentina-instagram-link" href={"https://www.instagram.com/p/CrB1vNfuxmU/?utm_source=ig_web_copy_link"} target="_blank" rel="noreferrer">
                    <Image src={argentinaInstagram} preview={false} />
                </a>
            </div>
        </div>
    );
}