import React from 'react';

import { Expandable } from '../common';

import './ParentsAndFamilies.css';

interface ParentsAndFamiliesProps {

}
interface FAQ {
    question: string;
    content: any;
}

export const ParentsAndFamilies = (props: ParentsAndFamiliesProps) => {

    const faqItems: FAQ[] = [
        {
            question: 'Who can refer a child to our program through TCRC or RCRC?',
            content: <p>Anyone can make a referral (parents, teachers, medical professionals, social workers etc) to this program. It is also important to provide the <a href="https://www.tri-counties.org/early-start-services/" target="_blank" rel="noreferrer">Tri-Counties Regional Center (TCRC)</a>, or <a href="https://www.redwoodcoastrc.org/" target="_blank" rel="noreferrer">Redwood Coast Regional Center (RCRC)</a> Service Coordinator with the family&apos;s contact information as well as, as much information about the child&apos;s developmental background and the potential concerns.</p>
        },
        {
            question: 'How is a child tested?',
            content: <p>Every child is evaluated in the family&apos;s home or a natural environment (ex., school, daycare) by the licensed and certificated therapist/specialist. This process entails completing a standardized testing tool that is appropriate and standardized for the child&apos;s primary language if different from English, observation of the child and of course, an interview with the family/caregiver/professional.</p>
        },
        {
            question: 'How soon will my child be able to start services?',
            content: <p>The process from start to finish usually entails at least a month to 6 weeks before receiving the results and feedback from the TCRC, or RCRC Service Coordinator.</p>

        },
        {
            question: 'Where are the services conducted?',
            content: <p>As stated by California law, all services are conducted within the child&apos;s family home, a caregiver&apos;s home, a daycare, preschool or in the community as appropriate (park, zoo, beach).</p>

        },
        {
            question: 'How often will a child be seen?',
            content: <p>This all depends on the need of the child but are typically 2 - 4 times a month. Each session lasts for a 60 minutes and is conducted in either a &apos;direct&apos; or &apos;consultation&apos; service model with each therapist/specialist.</p>
        },
        {
            question: 'How much does it cost?',
            content: <p>Nothing. The Early Start program is a federally funded program, which, is run through the Department of Developmental Services (DDS) in the State of California. Therefore, families do not pay for any services provided to their child through this program.</p>
        },
        {
            question: 'What happens when my child turns 3?',
            content: <p>Once a child turns 3, the Early Start program ends and should a child require continued services, they will then be served through the educational system. Our sister agency <a href="https://mphealthsb.com/" target="_blank" rel="noreferrer">MP Health</a> also provides specialized services (speech, occupational and physical therapy, vision, counseling, and educational and behavioral support) at our clinics in Santa Barbara and Atascadero. Contact MP Health for more information.</p>
        }
    ]

    const renderFaqItems = () => {
        return (
            faqItems.map((faqItem) => <Expandable header={faqItem.question} key={faqItem.question}>{faqItem.content}</Expandable>)
        )
    }

    return (
        <div className="page parents-and-families">
            <p className="description">Our goal at Mariposas Project is to assist a child&apos;s family and caregivers through the Early Start program in an easy and efficient manner in order to provide as much clarity as possible. There are many questions along the way and we hope these FAQ&apos;s can answer the most important and pertinent ones at the onset of this program:</p>
            <h2 className="title">FAQ&apos;s</h2>
            <hr />
            {renderFaqItems()}
        </div>
    );
}