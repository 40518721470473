import React, { createRef } from 'react';
import { Carousel, Image, Card, Col, Row } from 'antd';

import { LeftOutlined, RightOutlined, ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import toyDrive from '../../../static/img/flyer/MP-Toy-Drive.jpg';
import toyDriveAtascadero from '../../../static/img/flyer/MP-Toy-Drive-Atascadero.jpg';


import './CustomCarousel.css';

const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

interface CustomCarouselItem {
    title: string;
    image: string;
    children?: any;
}

interface CustomCarouselProps {
    children?: any;
    header?: string;
    bannerImage: string;
    bannerText: string;
    items: CustomCarouselItem[];
}

const settings = {
    autoplay: true,
    autoplaySpeed: 3500,
}

export const CustomCarousel = (props: CustomCarouselProps) => {
    const { children, header, bannerImage, bannerText, items } = props;

    return (
        <div className="custom-carousel-wrapper">
            {header ? <div className="custom-carousel-header">
                <h2>{header}</h2>
            </div> : null}
            <div className="carousel-wrapper">
                <Carousel 
                    arrows // Uncomment to show arrows
                    {...settings}
                    nextArrow={<RightOutlined style={{ fontSize: '48px' }} />}
                    prevArrow={<LeftOutlined style={{ fontSize: '48px' }} />}
                    >
                    {items.map((item, i) => (
                        <div key={i} className={"custom-carousel-item"}>
                            <div className={"custom-carousel-item-content"}>
                                <div className="custom-carousel-item-content-wrapper">
                                    <Card className="custom-carousel-item-content-tile" bordered={false} title={item.title} cover={<img alt={item.title} src={item.image} />} />
                                    <div className="custom-carousel-item-content-children">
                                        {item.children}
                                    </div>
                                    {/* <div className="custom-carousel-item-content-image">
                                        <Image src={item.image} height={'50vh'}/>
                                    </div>
                                    <div className="custom-carousel-item-content-children">
                                        {item.children}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            {/* 
                // Uncomment for "wave" border
                <div className='banner-divider-wrapper'>
                    <svg version="1.1" className="banner-divider secondary" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1440 126" preserveAspectRatio="none slice">
                        <path className="st0" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z"></path>
                    </svg>
                </div>
            */}
        </div>
    );
}