import React from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom'

import { PHONE, FAX, EMAIL, ADDRESS, NavigationItemsList, NavigationListItem } from '../../utils';

import './Footer.css';
import logo from '../../static/img/Mariposas-Project-Logo-vectorized-white.png';
import mpHealthLogo from '../../static/img/cropped-MPHealthLogo.png';

const { street, city, state, zipcode } = ADDRESS;

export const Footer = () => {

    return (
        <>
            <svg version="1.1" id="footer-divider" className="secondary" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1440 126" preserveAspectRatio="none slice">
                <path className="st0" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z"></path>
            </svg>
            <div id="footer">
                <div className="logos-container">
                    <div className="logos-wrapper">
                        <Link to="/">
                            <Image preview={false} src={logo}/>
                        </Link>
                    </div>
                </div>
                <div className="contact-container">
                    <div className="contact-wrapper">
                        <h3>Contact Us</h3>
                        <div className="phone">Phone: <a href={`tel:${PHONE}`}>{PHONE}</a></div>
                        <div className="fax">Fax: <a href={`tel:${FAX}`}>{FAX}</a></div>
                        <div className="email"><a href={`mailto:${EMAIL}`}>{EMAIL}</a></div>
                        <div className="address"><span>{`${street}, ${city}, ${state} ${zipcode}`}</span></div>
                    </div>
                </div>
                <div className="hours-container">
                    <div className="hours-wrapper">
                        <h3>Working Hours</h3>
                        <div>Monday to Friday</div>
                        <div>Open from 9am - 5pm</div>
                        <div>Holidays - Closed</div>
                        <div>Weekends - Closed</div>
                    </div>
                </div>
            </div>
        </>
    )
}