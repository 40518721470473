import React from 'react';
import { Map } from '../common';

import { 
    ADDRESS,
    ATASCADERO_ADDRESS,
    EUREKA_ADDRESS,
    DEL_NORTE_DEVELOPMENT_ADDRESS
} from '../../utils';

import './Locations.css';

interface LocationsProps {}

const maps = [
    { title: 'Santa Barbara Location', address: ADDRESS },
    { title: 'Atascadero Location', address: ATASCADERO_ADDRESS },
    { title: 'Eureka Location', address: EUREKA_ADDRESS },
    { title: 'Del Norte Development Center Location', address: DEL_NORTE_DEVELOPMENT_ADDRESS },
]

export const Locations = (props: LocationsProps) => {
    return (
        <div className="locations page">
            {maps.map(({ title, address }) => (
                <div className="location-container" key={title}>
                    <h1>{title}</h1>
                    <Map 
                        markerLocation={[address.location.lat, address.location.lng]}
                        defaultLocation={[address.location.lat, address.location.lng]}
                        address={address}
                    />
                </div>
            ))}
        </div>
    );
}