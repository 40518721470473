import React, { useEffect, useState } from 'react';
import { Map, Marker } from 'pigeon-maps';
import DirectionsIcon from '@mui/icons-material/Directions';

import { Address, generateDirectionsUrl } from '../../../utils/maps';

import './Map.css';
import { Dir } from 'fs';

interface CustomMapProps {
    [key: string]: any;
    markerLocation?: [number, number];
    defaultLocation?: [number, number];
    zoom?: number;
    address?: Address;
}

export const CustomMap = (props: CustomMapProps) => {
    const [center, setCenter] = useState<[number, number]>([50.879, 4.6997])
    const [zoom, setZoom] = useState(13);

    useEffect(() => {
        setCenter(props.markerLocation || props.defaultLocation || center);

    }, [props.markerLocation, props.defaultLocation, props.zoom]);

    return (
        <div className="map-wrapper">
            {
                props.address ? 
                    <a href={generateDirectionsUrl(props.address)} target="_blank" rel="noreferrer">
                        <div className="map-address-card">
                            <div className="text">
                                <span className="label">{props.address.street}</span>
                                <br />
                                <span className="subtext">{props.address.street}</span>
                                <br />
                                <span className="subtext">{`${props.address.city}, ${props.address.state} ${props.address.zipcode}`}</span>
                            </div>
                            <div className="icon">
                                <DirectionsIcon />
                            </div>
                        </div> 
                    </a> :
                    <></>
            }
            <Map 
                center={center} 
                zoom={zoom} 
                onBoundsChanged={({ center, zoom }) => { 
                    setCenter(center) 
                    setZoom(zoom) 
                }} 
            >
                <Marker 
                    width={50}
                    anchor={props.markerLocation || props.defaultLocation || center} 
                />
            </Map>
        </div>
    )
}
