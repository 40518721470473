import React from 'react';
import { Image } from 'antd';

import { PageHeader, SiteButton, SawyerWidget } from '../../common';
import { EMAIL, EMPLOYMENT_APPLICATION_LINK } from '../../../utils';

import './widgets.css';

interface EurekaMariposasPlayCenterProps {

}

export const EurekaMariposasPlayCenter = (props: EurekaMariposasPlayCenterProps) => {
    const HEADER = 'Eureka Mariposas Play Center';
    const ID = 'SA_4bVxW5XNTHzUReCxIU3KbRIgnkzKdB0D';
    const SRC = 'https://hisawyer.com/embed/4bVxW5XNTHzUReCxIU3KbRIgnkzKdB0D.js';

    const subheader = () => (
        <>
            <span style={{ fontSize: '1.5rem' }}>1590 Myrtle Avenue, Eureka CA 95501</span>
            <br />
            <span style={{ fontSize: '1.5rem' }}>707-250-8225</span>
        </>
    )
    
    return (
        <div className="eureka page">
            <PageHeader header={HEADER} subheader={subheader()} />
            {/* <div className="contact">
                <h3>Contact:</h3>
                <p>
                    1590 Myrtle Avenue, Eureka CA 95501
                </p>
                <p>
                    707-250-8225
                </p>
            </div> */}
            <div className="eureka-blocks">
               <SawyerWidget 
                    id={ID}
                    src={SRC}
                />
            </div>
        </div>
    );
}