const { REACT_APP_ENV, STAGE, REACT_APP_CAPTCHA_SITE_KEY } = process.env;

export const RECAPTCHA_SITE_KEY = REACT_APP_CAPTCHA_SITE_KEY;

export const BASE_URL = 'https://mariposasproject.com'
export const BASE_PATH = `${REACT_APP_ENV === 'production' ? '' : ''}`;

export const EMAIL = "info@mariposasproject.com";
export const PHONE = "(805) 259-7820";
export const FAX = "(805) 823-4500";
export const ADDRESS = {
    street: "215 W Mission St",
    city: "Santa Barbara",
    state: "CA",
    zipcode: "93101",
    location: {
        lat: 34.4280691,
        lng: -119.7194234
    }
}
export const ATASCADERO_PHONE = '(805) 637-0802';
export const ATASCADERO_ADDRESS = {
    street: "6895 Morro Rd",
    city: "Atascadero",
    state: "CA",
    zipcode: "93422",
    location: {
        lat: 35.4814824,
        lng: -120.6680583
    }
}
// 1590 Myrtle Avenue, Eureka, CA 95501
export const EUREKA_ADDRESS = {
    street: "1590 Myrtle Ave",
    city: "Eureka",
    state: "CA",
    zipcode: "95501",
    location: {
        lat: 40.79538567138123,
        lng: -124.1373278323671
    }
}
export const DEL_NORTE_DEVELOPMENT_ADDRESS = {
    street: "1041 5th St",
    city: "Crescent City",
    state: "CA",
    zipcode: "95531",
    location: {
        lat: 41.75459118628214,
        lng: -124.19674224581128
    }
}
export const HUMBOLDT_EMAIL = 'info.humboldt@mariposasproject.com';
export const FACEBOOK_URL = "https://www.facebook.com/MariposasProjectInc/";
export const EMPLOYMENT_APPLICATION_LINK = 'https://pdf.ac/AuriF';
export const ADMISSION_INTAKE_AND_CONSENT_FORM_URL = "https://pdf.ac/NFNsc";
export const ADMISSION_INTAKE_AND_CONSENT_FORM_ES_URL = "https://pdf.ac/EdDAd";

export const STATIC_MEDIA_URL = "/static/media";

export interface NavigationItem {
    key: string;
    label: string;
    path: string;
    metadata?: {
        description?: string | string[];
        image?: string; // Parent navigation humbnail
    };
    disabled?: boolean;
    external?: boolean;
}

export interface NavigationListItem {
    item: NavigationItem;
    subItems?: NavigationItem[];
}

export const NavigationItemsList: NavigationListItem[] = [
    {
        item: { key: "therapy_services", path: "/therapy_services", label: "Therapy Services" }, 
    },
    {
        item: { key: "early_start_services", path: "/early_start_services", label: "Early Start Services" },
    },
    {
        item: { key: "parents_and_families", path: "/parents_and_families", label: "Parents & Families" },
    },
    // {
    //     item: { key: "argentina", path: "/argentina", label: "Argentina" },
    // },
    {
        item: { key: "mphealth", path: "https://mphealthsb.com/", label: "MP Health", external: true },
    },
    {
        item: { key: "sign_up", path: "#", label: "Sign Up" },
        subItems: [
            { key: "eureka", path: "/sign_up/eureka", label: "Eureka Mariposas Play Center", },
            { key: "atascadero", path: "/sign_up/atascadero", label: "Mariposas Atascadero", },
            { key: "santabarbara", path: "/sign_up/santabarbara", label: "Mariposas Santa Barbara", },
        ]
    },
    {
        item: { key: "contact_us", path: "/contact_us", label: "Contact Us" },
        subItems: [
            { key: "locations", path: "/contact_us/locations", label: "Locations", },
            { key: "employment", path: "/contact_us/employment", label: "Employment", },
            { key: "argentina", path: "/argentina", label: "Argentina" },
        ]
    },
    {
        item: { key: "cloud", path: "https://cloud.mariposasproject.com/index.php/login", label: "Cloud", external: true },
    },
]

export const isEven = (x: number) => x % 2 == 0;