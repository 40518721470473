import React from 'react';
import { Link } from 'react-router-dom';

import './SiteButton.css';

interface SiteButtonProps {
    url: string;
    label: string;
    sameTab?: boolean;
    route?: boolean;
    style?: { [key: string]: string };
}

export const SiteButton = (props: SiteButtonProps) => {
    const { url, label, sameTab, route } = props;

    const styles = props.style ? props.style : {};

    if (route) return (
        <Link className="site-button" style={props.style || {}} to={url}><div>{label}</div></Link>
    );

    if (sameTab) return (
        <a className="site-button" style={props.style || {}} href={url}>
            <div>{label}</div>
        </a>
    );
    
    return (
        <a className="site-button" style={props.style || {}} href={url} target="_blank" rel="noreferrer">
            <div>{label}</div>
        </a>
    );
}