import React from 'react';
import { Modal, Result, Button } from 'antd';

interface SuccessProps {
    isVisible?: boolean
    title: string;
    subtitle?: string;
    handleClose: () => void;
}

export const Success = (props: SuccessProps) => {
    return (
        <Modal visible={props.isVisible} footer={null} closable={false}>
            <Result
                status="success"
                title={props.title}
                subTitle={props.subtitle}
                extra={[
                    <Button key="close" onClick={props.handleClose}>Close</Button>,
                ]}
            />
        </Modal>
    )
}